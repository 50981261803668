<!-- eslint-disable vuejs-accessibility/no-autofocus -->
<template>
  <div
    ref="searchElement"
    class="search-form"
    :class="{
      'max-w-4xl': !showAirports,
      'max-w-7xl': showAirports,
      'grid-cols-2 sm:grid lg:flex': showAirports,
    }"
  >
    <div
      v-if="showAirports"
      class="border-b border-gray-300 pb-5 sm:order-3 sm:border-b-0 sm:pb-0 sm:pr-4 md:pr-7 lg:w-52 flex-1 lg:order-1 lg:border-r"
    >
      <client-only>
        <template #placeholder>
          <div class="md:w-[289px] h-[81px] md:h-[60px]">
            <PickerPlaceholder divider alt />
          </div>
        </template>
        <div class="flex-1">
          <AirportPicker
            ref="airportPicker"
            class="search-form__airport-picker flex-1"
            :preselect-airport="airportSelected"
            :expression="airportExpression"
            @collapsed="handleHighlightDisable"
            @airportSelected="airportSelectionChanged"
            @clearExpression="airportExpression = undefined"
            @expanded="collapseOthers($refs.airportPicker)"
          >
            <template #default="{ isExpanded }">
              <div
                role="button"
                tabindex="-1"
                class="cursor-pointer search-form__airport-picker__label"
                @keypress.stop.prevent="handleAirportToggle"
                @click.stop.prevent="handleAirportToggle"
              >
                {{ capitalizeFirstLetter($i18n('general.airport')) }}
              </div>
              <div
                v-if="$isMobile || !isExpanded"
                role="button"
                tabindex="0"
                class="search-form__airport-picker__airport flex cursor-pointer place-items-center"
                @keypress.stop.prevent="handleAirportToggle"
                @click.stop.prevent="handleAirportToggle"
                @keydown.enter.prevent="handleAirportToggle"
              >
                <div class="overflow-hidden text-ellipsis whitespace-nowrap">
                  <template v-if="airportSelected">
                    {{ airportSelected.name }}
                  </template>
                  <template v-else>
                    {{ $i18n('reservation.choose-aiport') }}
                  </template>
                </div>
                <CaretTransition
                  :show="isExpanded"
                  class="search-form__airport-picker__airport__caret ml-auto shrink-0 text-gray-400 xs:ml-1 sm:ml-2"
                />
              </div>
              <div v-else>
                <input
                  ref="airportSearchInput"
                  v-model="airportExpression"
                  aria-label="airport search input"
                  tabindex="0"
                  autofocus
                  class="appearance-none outline-none"
                  :placeholder="$i18n('general.search')"
                  type="text"
                  @keydown.enter.prevent="handleAirportToggle"
                />
              </div>
            </template>
          </AirportPicker>
        </div>
      </client-only>
    </div>

    <client-only>
      <template #placeholder>
        <div class="md:w-[289px] h-[81px] md:h-[60px]">
          <PickerPlaceholder divider alt />
        </div>
      </template>

      <div
        class="flex-1 border-b border-gray-300 pb-5 sm:order-1 sm:border-r sm:border-b-0 sm:pb-0 sm:pr-4 md:pr-7 lg:order-2"
      >
        <div class="flex-1">
          <div class="flex items-center flex-1 leading-relative">
            <paDatePicker
              ref="dateTimePickerStart"
              :min-date="new Date()"
              :default-time="defaultTime"
              :date="dateRange.start"
              :dateOther="dateRange.end"
              @collapsed="handleHighlightDisable"
              @expanded="collapseOthers($refs.dateTimePickerStart)"
              @dateChanged="dateStartChanged"
            >
              <template #date="{ toggle, isExpanded }">
                <div
                  role="button"
                  tabindex="-1"
                  class="font-cocogoose text-md md:text-15 cursor-pointer whitespace-nowrap"
                  @keypress.stop.prevent="handleToggleFunc(toggle, 'dateStart')"
                  @click.stop.prevent="handleToggleFunc(toggle, 'dateStart')"
                >
                  <span v-if="be606ukQuickWinsRunning"> Drop Off Date </span>
                  <span v-else>
                    {{ capitalizeFirstLetter($i18n('templates.date-from')) }}
                  </span>
                </div>
                <div
                  role="button"
                  tabindex="0"
                  class="text-md md:text-base flex cursor-pointer items-center"
                  :class="{ 'md:mr-7': !isUSDomain, 'md:mr-5': isUSDomain }"
                  @keypress.stop.prevent="handleToggleFunc(toggle, 'dateStart')"
                  @click.stop.prevent="handleToggleFunc(toggle, 'dateStart')"
                  @keydown.enter.prevent="handleToggleFunc(toggle, 'dateStart')"
                >
                  <div class="whitespace-nowrap">
                    <template v-if="!dateRange.start">
                      {{ placeholder }}
                    </template>
                    <template v-else>
                      {{ formatDate(dateRange.start) }}
                    </template>
                  </div>
                  <CaretTransition
                    class="ml-2 text-gray-400"
                    :show="isExpanded && !isOnlyFirstDateSelected"
                  />
                </div> </template
            ></paDatePicker>
            <LegacyTimePicker
              ref="timePickerStart"
              class="ml-auto px-1 xs:ml-0"
              :value="arrivalTime"
              :class="{ 'mt-auto': !be606ukQuickWinsRunning }"
              @expanded="collapseOthers($refs.timePickerStart)"
              @collapsed="handleHighlightDisable"
              @timeChanged="timeStartChanged"
            >
              <template #header>
                <div
                  v-if="be606ukQuickWinsRunning"
                  class="text-gray-500 md:text-black md:font-cocogoose font-normal md:text-15"
                >
                  <span> Time </span>
                </div>
              </template>
            </LegacyTimePicker>
            <div class="mb-2 mt-auto shrink-0">
              <Tooltip
                class="ml-2 xxs:ml-4 xs:ml-6 sm:ml-4 md:ml-3 hidden xxxs:block"
              >
                <div>
                  {{ $i18n('search.arrival-time-explanation-general') }}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </client-only>

    <client-only>
      <template #placeholder>
        <div class="md:w-[268px] h-[60px]">
          <PickerPlaceholder alt />
        </div>
      </template>

      <div class="flex-1 sm:order-2 md:pr-2 lg:order-3">
        <div class="flex-1">
          <div class="flex items-center flex-1 leading-relative">
            <paDatePicker
              ref="dateTimePickerEnd"
              :min-date="minimumDepartureDate"
              :default-time="defaultTime"
              :date="dateRange.end"
              :dateOther="dateRange.start"
              :is-end-date="true"
              :placeholder="$i18n('templates.select')"
              @dateChanged="dateEndChanged"
              @collapsed="handleHighlightDisable"
              @expanded="collapseOthers($refs.dateTimePickerEnd)"
            >
              <template #date="{ toggle, isExpanded }">
                <div
                  role="button"
                  tabindex="0"
                  class="font-cocogoose text-md md:text-15 cursor-pointer whitespace-nowrap"
                  :class="{ 'text-black-alt-500': isOnlyFirstDateSelected }"
                  @keypress.stop.prevent="handleToggleFunc(toggle, 'dateEnd')"
                  @click.stop.prevent="handleToggleFunc(toggle, 'dateEnd')"
                >
                  <span v-if="be606ukQuickWinsRunning"> Pick Up Date </span>
                  <span v-else>
                    {{ capitalizeFirstLetter($i18n('templates.date-until')) }}
                  </span>
                </div>
                <div
                  role="button"
                  tabindex="0"
                  class="text-md md:text-base flex cursor-pointer items-center"
                  :class="{ 'md:mr-7': !isUSDomain, 'md:mr-5': isUSDomain }"
                  @keypress.stop.prevent="handleToggleFunc(toggle, 'dateend')"
                  @click.stop.prevent="handleToggleFunc(toggle, 'dateend')"
                  @keydown.enter.prevent="handleToggleFunc(toggle, 'dateEnd')"
                >
                  <div class="whitespace-nowrap">
                    <template v-if="!dateRange.end">
                      {{ placeholder }}
                    </template>
                    <template v-else>
                      {{ formatDate(dateRange.end) }}
                    </template>
                  </div>
                  <CaretTransition
                    class="ml-2 text-gray-400"
                    :show="isExpanded || isOnlyFirstDateSelected"
                  />
                </div> </template
            ></paDatePicker>
            <LegacyTimePicker
              ref="timePickerEnd"
              class="ml-auto mt-auto px-1 xs:ml-0"
              :date-range="dateRange"
              :threshold="isSameDate ? arrivalTime : undefined"
              :value="departureTime"
              :is-end-time="true"
              @expanded="collapseOthers($refs.timePickerEnd)"
              @collapsed="handleHighlightDisable"
              @timeChanged="timeEndChanged"
            >
              <template #header>
                <div
                  v-if="be606ukQuickWinsRunning"
                  class="text-gray-500 md:text-black md:font-cocogoose font-normal md:text-15"
                >
                  <span> Time </span>
                </div>
              </template>
            </LegacyTimePicker>
            <div class="mb-2 mt-auto shrink-0">
              <Tooltip
                class="ml-2 xxs:ml-4 xs:ml-6 sm:ml-4 md:ml-3 hidden xxxs:block"
              >
                <div>
                  {{ $i18n('search.departure-time-explanation-general') }}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </client-only>

    <div class="order-4 w-full grow-0 self-center lg:w-52">
      <button
        class="w-full text-md md:text-15 font-cocogoose cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap rounded-md bg-orange-500 px-2 py-3 text-center text-white hover:bg-blaze-orange-500"
        @click.stop.prevent="searchParkings"
      >
        {{ $i18n('home.search-parkingplace') }}
      </button>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import Tooltip from '~/components/header/Tooltip.vue';
import CaretTransition from '~/components/CaretTransition.vue';
import {
  getQueryFromLocalStorage,
  setQueryInLocalStorage,
} from '~/helpers/date-sync';
import AirportPicker from './AirportPicker.vue';
import LegacyTimePicker from './LegacyTimePicker.vue';
import QuickBookBadgeV2 from '../3min-to-book-badge/QuickBookBadgeV2.vue';
import ExperimentToggle from '../ExperimentToggle.vue';
import paDatePicker from './paDatePicker.vue';

const PickerPlaceholder = () =>
  import('~/components/search/PickerPlaceholder.vue');

export default {
  components: {
    paDatePicker,
    ExperimentToggle,
    LegacyTimePicker,
    AirportPicker,
    Tooltip,
    CaretTransition,
    QuickBookBadgeV2,
    PickerPlaceholder,
  },
  props: {
    showAirports: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    const dateStart = null;
    const dateEnd = null;
    return {
      dateRange: {
        start: undefined,
        end: undefined,
      },
      mobileDateRangeProp: { start: dateStart, end: dateEnd },
      timeRange: {},
      defaultTime: new Date(dateEnd),
      airportSelected: this.$store.state.airport,
      placeholder: this.$i18n('templates.select'),
      highlightTimeoutHandle: undefined,
      airportExpression: undefined,
      isOnlyFirstDateSelected: false,
      lastDraggingStartDate: 0,
      clearToggleListener: () => {},
      pickerList: [],
      highlighted: false,
      departureTime: '12:00',
      arrivalTime: '12:00',
      dirtinessMap: [],
      be606ukQuickWinsRunning: false,
    };
  },
  computed: {
    minimumDepartureDate() {
      if (this.isLateArrival) {
        return this.addDays(this.dateRange.start, 1);
      }
      return this.dateRange.start || new Date();
    },
    isSameDate() {
      if (!this.dateRange || !this.dateRange.start || !this.dateRange.end) {
        return false;
      }
      const [dateStartStr] = this.dateRange.start.toISOString().split('T');
      const [dateEndStr] = this.dateRange.end.toISOString().split('T');
      return dateEndStr === dateStartStr;
    },
    isLateArrival() {
      return this.arrivalTime === '23:45';
    },
    language() {
      return this.$store.state.language;
    },
    languages() {
      return this.$store.state.languages;
    },
    isUSDomain() {
      return this.$store.state.language.lang === 'en-us';
    },
    airport() {
      return this.$store.state.airport;
    },
    dateFormating() {
      const isAtDomain = this.language?.lang === 'de-at';
      return isAtDomain
        ? { day: 'numeric', month: 'numeric', year: 'numeric' }
        : { day: '2-digit', month: '2-digit', year: 'numeric' };
    },
  },
  watch: {
    dateRange: {
      deep: true,
      handler(value) {
        this.$store.commit('bucket/update', {
          key: 'arrival_date',
          value: this.formatApiDate(value.start),
        });
        this.$store.commit('bucket/update', {
          key: 'departure_date',
          value: this.formatApiDate(value.end),
        });
        setQueryInLocalStorage({
          ...getQueryFromLocalStorage(this.$store.state.airport.slug),
          arrival: dayjs(value.start).format('YYYY-MM-DD'),
          departure: dayjs(value.end).format('YYYY-MM-DD'),
        });
      },
    },
    arrivalTime(value) {
      this.$store.commit('bucket/update', {
        key: 'arrival_time',
        value: `${value}`,
      });
      setQueryInLocalStorage({
        ...getQueryFromLocalStorage(this.$store.state.airport.slug),
        arrivalTime: value,
        arrival: dayjs(this.dateRange.start).format('YYYY-MM-DD'),
        departure: dayjs(this.dateRange.end).format('YYYY-MM-DD'),
        location: this.airportSelected?.slug ?? '',
      });
    },
    departureTime(value) {
      this.$store.commit('bucket/update', {
        key: 'departure_time',
        value: `${value}`,
      });
      setQueryInLocalStorage({
        ...getQueryFromLocalStorage(this.$store.state.airport.slug),
        departureTime: value,
        arrival: dayjs(this.dateRange.start).format('YYYY-MM-DD'),
        departure: dayjs(this.dateRange.end).format('YYYY-MM-DD'),
        location: this.airportSelected?.slug ?? '',
      });
    },
  },
  created() {
    this.be606ukQuickWinsRunning =
      this.$store?.getters?.experimentVariant(this.name) === 'b';

    this.clearToggleListener = this.$store.subscribeAction((action) => {
      if (!action || action.type !== 'airportPicker/toggle') {
        return;
      }
      this.handleAirportToggle();
    });
    this.$store.commit('airportPicker/select', this.airportSelected);

    if (process.browser) {
      const query = getQueryFromLocalStorage(this.$store.state.airport.slug);
      this.dateRange.start = query.arrival;
      this.dateRange.end = query.departure;

      this.arrivalTime = query.arrivalTime;
      this.departureTime = query.departureTime;
    }
  },
  beforeDestroy() {
    this.clearToggleListener();
  },
  mounted() {
    window.addEventListener('hashchange', (event) => {
      const urlOld = new URL(event.oldURL);
      const urlNew = new URL(event.newURL);
      if (urlOld.hash !== '#picker' || urlNew.hash === '#picker') {
        return;
      }
      const refs = [
        'airportPicker',
        'mobilePickerDateStart',
        'mobilePickerDateEnd',
        'mobilePickerTimeStart',
        'mobilePickerTimeEnd',
      ];
      refs.forEach((ref) => {
        const refObj = this.$refs[ref];
        if (refObj && refObj.isExpanded) {
          refObj.collapse();
        }
      });
    });
    setTimeout(() => {
      this.pickerList = [
        this.$refs.airportPicker,
        this.$refs.timePickerStart,
        this.$refs.timePickerEnd,
        this.$refs.dateTimePickerStart,
        this.$refs.dateTimePickerEnd,
      ];
      this.dirtinessMap = [
        {
          name: 'airport',
          next: 'dateStart',
          dirty: false,
          element: this.$refs.airportPicker,
          priority: 5,
        },
        {
          name: 'dateStart',
          next: 'timeStart',
          dirty: false,
          element: this.$refs.dateTimePickerStart,
          priority: 1,
        },
        {
          name: 'timeStart',
          next: 'dateEnd',
          dirty: false,
          element: this.$refs.timePickerStart,
          priority: 2,
        },
        {
          name: 'dateEnd',
          next: 'timeEnd',
          dirty: false,
          element: this.$refs.dateTimePickerEnd,
          priority: 3,
        },
        {
          name: 'timeEnd',
          next: 'airport',
          dirty: false,
          element: this.$refs.timePickerEnd,
          priority: 4,
        },
      ];
    });
  },
  methods: {
    timeStartChanged(value) {
      this.arrivalTime = value;
      this.preventGreaterArrivalDate();
      this.expandNextPicker('timeStart');
    },
    preventGreaterArrivalDate() {
      let [hours, minutes] = this.arrivalTime.split(':');
      let [hoursDep, minutesDep] = this.departureTime.split(':');
      hours = +hours;
      minutes = +minutes;
      hoursDep = +hoursDep;
      minutesDep = +minutesDep;
      if (
        this.isSameDate &&
        hours * 60 * 60 + minutes * 60 >= hoursDep * 60 * 60 + minutesDep * 60
      ) {
        if (this.isLateArrival) {
          this.dateRange.end = this.addDays(this.dateRange.start, 1);
          this.departureTime = '0:00';
        } else {
          if (minutes < 45) {
            minutes += 15;
          } else if (hours < 24) {
            hours += 1;
            minutes = 0;
          } else {
            hours = '0';
            minutes = '00';
          }

          if (minutes < 10) {
            minutes = `0${minutes}`;
          }
          this.departureTime = `${hours}:${minutes}`;
        }
      }
    },
    addDays(date, days) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    timeEndChanged(value, skipExpand) {
      this.departureTime = value;
      if (!skipExpand) {
        this.expandNextPicker('timeEnd');
      }
    },
    collapseOthers(picker) {
      if (!this.hightlighted) {
        this.enableHighlight();
      }
      if (this.$refs.airportPicker && picker !== this.$refs.airportPicker) {
        this.$refs.airportPicker.collapse();
      }
      this.pickerList.forEach((e) => {
        if (!e || e === picker) {
          return;
        }
        if (typeof e.collapse === 'function') {
          e.collapse();
        }
        if (typeof e.collapseDate === 'function') {
          e.collapseDate();
        }
      });
    },
    focusAirportSearch(delay = 150) {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.airportSearchInput) {
            this.$refs.airportSearchInput.focus();
          }
        }, delay);
      });
    },
    searchParkings() {
      if (this.showAirports && !this.airportSelected) {
        this.handleAirportToggle();
        if (!this.$isMobile) {
          this.$refs.dateTimePickerStart.collapseDate();
          this.$refs.dateTimePickerEnd.collapseDate();
        }
        return;
      }
      const airportSlug = this.airportSelected.slug;
      const now = new Date();
      const startDate =
        this.dateRange.start ||
        new Date(new Date(now).setDate(now.getDate() + 7));
      const fallBackEndDate = new Date(
        new Date(now).setDate(now.getDate() + 14)
      );
      const endDate = this.dateRange.end || fallBackEndDate;
      const { arrivalTime } = this;
      const { departureTime } = this;
      const arrivalDate = this.formatApiDate(startDate);
      const departureDate = this.formatApiDate(endDate);
      const url = `/${airportSlug}/${this.$i18n(
        'general.search-slug'
      )}/?location=${airportSlug}&arrivalTime=${arrivalTime}&departureTime=${departureTime}&arrival=${arrivalDate}&departure=${departureDate}`;
      window.location.href = url;
      setQueryInLocalStorage({
        ...getQueryFromLocalStorage(this.$store.state.airport.slug),
        arrival: arrivalDate,
        arrivalTime,
        departure: departureDate,
        departureTime,
        location: airportSlug,
      });
    },
    formatApiDate(date) {
      let day = date.getDate();
      if (day < 10) {
        day = `0${day}`;
      }
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      return `${date.getFullYear()}-${month}-${day}`;
    },
    capitalizeFirstLetter(str) {
      const strippedStr = this.$sanitize(str, true);
      if (strippedStr.length < 1) {
        return strippedStr;
      }
      const arr = strippedStr.split('');
      arr[0] = arr[0].toLocaleUpperCase();

      return arr.join('');
    },
    enableHighlight() {
      clearTimeout(this.highlightTimeoutHandle);
      if (this.$isMobile) {
        return;
      }
      this.highlighted = true;
      this.$store.commit('highlight/show', this.$refs.searchElement);
    },
    disableHighlight() {
      if (!this.highlighted) {
        return;
      }
      clearTimeout(this.highlightTimeoutHandle);
      if (this.$isMobile) {
        return;
      }
      this.highlighted = false;
      this.highlightTimeoutHandle = setTimeout(() => {
        this.$store.dispatch('highlight/hide');
      }, 150);
    },
    setClock(date, hours, minutes) {
      date.setHours(hours);
      date.setMinutes(minutes);
      return date;
    },
    formatDate(date) {
      if (!date) {
        return this.placeholder;
      }
      let day = date.toLocaleDateString(this.language.lang, {
        weekday: 'long',
      });
      if (this.language.lang === 'pl' && day === 'piątek') day = 'pt';
      return `${day.substr(0, day.length < 2 ? day : 2)}. ${Intl.DateTimeFormat(
        this.formatLangForDate(this.language.lang),
        this.dateFormating
      ).format(date)}`;
    },
    formatTime(time) {
      if (!time) {
        return `12:00 ${this.language.lang === 'en-us' ? ' PM' : ''}`;
      }
      let hours = time.getHours();
      let minutes = time.getMinutes();
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      let meridiem = '';
      if (this.language.lang === 'en-us') {
        meridiem = ' AM';
        if (hours > 12) {
          meridiem = ' PM';
          hours -= 12;
        } else if (hours === 12) {
          meridiem = ' PM';
        }
      }
      return `${hours}:${minutes}${meridiem}`;
    },
    formatLangForDate(lang) {
      if (lang === 'nl-be' || lang === 'en-eu') {
        return lang === 'nl-be' ? 'nl' : 'de';
      }
      return lang;
    },
    handleAirportToggle() {
      this.$nextTick(() => {
        const pickerRef = this.$refs.airportPicker;
        if (pickerRef.isExpanded) {
          this.disableHighlight();
        } else {
          if (!this.$isMobile) {
            this.$refs.dateTimePickerStart.collapseDate();
            this.$refs.dateTimePickerEnd.collapseDate();
          }
          this.enableHighlight();
        }
        pickerRef.toggle();
        this.focusAirportSearch(500);
      });
    },
    handleToggleFunc(toggleFunc, origin) {
      if (this.showAirports) {
        this.$refs.airportPicker.collapse();
      }
      const refStart = this.$refs.dateTimePickerStart;
      const refEnd = this.$refs.dateTimePickerEnd;
      const map = {
        dateStart: [refEnd.collapseDate],
        dateEnd: [refStart.collapseDate],
      };
      if (map[origin]) {
        setTimeout(() => {
          map[origin].forEach((f) => f());
        });
      }
      toggleFunc();
      this.enableHighlight();
    },
    handleHighlightDisable() {
      this.isOnlyFirstDateSelected = false;
      this.$nextTick(() => {
        if (this.showAirports && this.$refs.airportPicker.isExpanded) {
          return;
        }
        if (
          this.$refs.dateTimePickerStart.isExpanded ||
          this.$refs.dateTimePickerEnd.isExpanded ||
          this.$refs.timePickerStart.show ||
          this.$refs.timePickerEnd.show
        ) {
          return;
        }
        this.disableHighlight();
      });
    },
    airportSelectionChanged(airport) {
      setQueryInLocalStorage({
        ...getQueryFromLocalStorage(this.$store.state.airport.slug),
        location: airport?.slug ?? '',
        arrival: dayjs(this.dateRange.start).format('YYYY-MM-DD'),
        departure: dayjs(this.dateRange.end).format('YYYY-MM-DD'),
      });
      this.airportSelected = airport;
      this.$store.commit('airportPicker/select', airport);
      this.expandNextPicker('airport');
    },
    dateStartChanged(startData) {
      this.dateRange.start = new Date(startData);
      const setDate = (days, start) =>
        new Date(new Date(start).setDate(new Date(start).getDate() + days));
      const setFutureDate = setDate(7, this.dateRange.start);
      this.dateRange.end = setFutureDate;
      this.markDirty('dateEnd');
      this.expandNextPicker('dateStart');
    },
    markDirty(key) {
      const [picker] = this.dirtinessMap.filter((f) => f.name === key);
      if (!picker) {
        return;
      }
      picker.dirty = true;
    },
    dateEndChanged(data) {
      this.dateRange.end = new Date(data);
      if (!this.dateRange.start) {
        const fallbackDate = new Date();
        const dateStart = this.addDays(this.dateRange.end, -7);
        if (dateStart.getTime() > fallbackDate.getTime()) {
          this.dateRange.start = dateStart;
        } else {
          this.dateRange.start = fallbackDate;
        }
        this.markDirty('dateStart');
        this.expandNextPicker('dateEnd', 'timeEnd');
      } else {
        this.preventGreaterArrivalDate();
        this.expandNextPicker('dateEnd');
      }
    },
    collapsePickers() {
      if (this.$isMobile) {
        return;
      }
      this.$refs.dateTimePickerStart.collapseDate();
      this.$refs.dateTimePickerEnd.collapseDate();
    },
    expandNextPicker(key, nextItemToForceIfClean) {
      if (this.$isMobile) {
        return;
      }

      if (
        this.dirtinessMap.some((f) => f?.name !== key && f?.element?.isExpanded)
      ) {
        return;
      }

      this.markDirty(key);
      if (nextItemToForceIfClean) {
        const [nextItemToForceExpand] = this.dirtinessMap.filter(
          (a) => a.name === nextItemToForceIfClean
        );
        if (
          nextItemToForceExpand &&
          !nextItemToForceExpand.dirty &&
          nextItemToForceExpand.element
        ) {
          setTimeout(() => {
            nextItemToForceExpand.element.expand();
          }, 100);
        }
        return;
      }
      const [highestPrio] = this.dirtinessMap
        .sort(
          (a, b) =>
            (a.priority || Number.MAX_SAFE_INTEGER) -
            (b.priority || Number.MAX_SAFE_INTEGER)
        )
        .filter((a) => !a.dirty);
      if (!highestPrio || !highestPrio.element) {
        return;
      }
      setTimeout(() => {
        highestPrio.element.expand();
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.search-form {
  @apply flex-1 gap-7 relative inline-flex w-full select-none flex-col rounded-md bg-white p-30px font-sans xs:p-5 sm:flex-row sm:flex-wrap md:z-20 lg:flex-nowrap;

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    @apply gap-0;
    & > div {
      @apply mb-7;
      &:last-child {
        @apply mb-0;
      }
    }
  }

  &__airport-picker {
    $self: &;
    min-width: 180px;
    #{$self}__label {
      @apply text-gray-900 font-cocogoose text-[15px];
    }
    &:hover {
      #{$self}__label {
        @apply text-black-alt-500;
      }
      #{$self}__airport {
        &__caret {
          @apply text-orange-500;
        }
      }
    }
  }
  &__date-time-picker {
    $self: &;
    #{$self}__label {
      @apply text-gray-900 font-cocogoose text-[15px];
    }
    &:hover {
      #{$self}__label {
        @apply text-black-alt-500;
        &--gray {
          @apply text-gray-500;
        }
      }
    }
    &__date {
      &:hover {
        #{$self}__date__caret {
          @apply text-orange-500;
        }
      }
    }

    &__time-slot {
      $self: &;
      &:hover {
        #{$self}__caret {
          @apply text-orange-500;
        }
      }
    }
  }
}

.no-tap-highlight {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
